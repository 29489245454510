import classNames from 'classnames';
import {useEffect, useState, type ReactNode} from 'react';

import {Icon} from 'Components/icon';
import {TableHeaderButton} from 'Components/sharedTableSubComponents';
import {FullScreenButton} from 'Components/table/tableHeader/fullScreenButton';
import {parseUnsafeInt} from 'Utils/parseUnsafeInt';
import {splitArrayIntoChunks} from 'Utils/splitArrayIntoChunks';

import './responsiveContainer.scss';

type ResponsiveContainerProps = {
    children: ReactNode[];
    maxColumns?: number | string;
    sizeRatio?: string;
    title?: string;
    allowStretchingInPartialRows?: boolean;
};

export const ResponsiveContainer = ({
    children,
    maxColumns,
    sizeRatio: sizeRatioString,
    title,
    allowStretchingInPartialRows = true,
}: ResponsiveContainerProps) => {
    const parsedMaxColumns = parseUnsafeInt(maxColumns, 4);

    const [isFullscreen, setIsFullscreen] = useState(false);
    const [isMinimised, setIsMinimised] = useState(false);

    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    }, [isFullscreen]);

    const numberOfColumns =
        children &&
        (children.length > parsedMaxColumns
            ? parsedMaxColumns
            : children.length);

    const sizeRatio = parseUnsafeInt(sizeRatioString, 1);
    if (!children) {
        return null;
    }
    return (
        <div
            className={classNames('responsive-container__outer', {
                'responsive-container__outer--fullscreen': isFullscreen,
                'responsive-container__outer--bordered': title,
                'responsive-container__outer--minimised': isMinimised,
            })}
            // style={isMinimised ? {} : {flex: sizeRatio}}
            style={isMinimised ? {} : {flex: sizeRatio}}
        >
            {title && (
                <>
                    {!isMinimised && (
                        <h2 className="responsive-container__title">{title}</h2>
                    )}
                    <div className="responsive-container__floating-buttons">
                        {!isFullscreen && (
                            <TableHeaderButton
                                onClick={() => setIsMinimised((prev) => !prev)}
                            >
                                <Icon
                                    iconName={
                                        isMinimised
                                            ? 'arrow-go-down'
                                            : 'arrow-go-up'
                                    }
                                ></Icon>
                            </TableHeaderButton>
                        )}
                        {!isMinimised && (
                            <FullScreenButton
                                isFullScreen={isFullscreen}
                                setIsFullScreen={setIsFullscreen}
                            />
                        )}
                    </div>
                </>
            )}
            {!isMinimised &&
                splitArrayIntoChunks(children, parsedMaxColumns).map(
                    (chunk, index) => (
                        <div className="responsive-container" key={index}>
                            {chunk}
                            {!allowStretchingInPartialRows &&
                                chunk.length < numberOfColumns &&
                                // Add empty divs to fill the empty columns in the final row, to avoid
                                // items stretching. This is so that the KPI Panels all line up neatly
                                new Array(numberOfColumns - chunk.length)
                                    .fill(0)
                                    .map((_emptyPanel, innerIndex) => (
                                        <div
                                            className="responsive-container__empty-item-placeholder"
                                            key={innerIndex}
                                        />
                                    ))}
                        </div>
                    ),
                )}
        </div>
    );
};
