import {Serie} from '@nivo/line';

import {ApiResponse, Data} from '../types';

const formatSqlString = (sqlString: string): string => {
    const lcString = sqlString.replace(/_/g, ' ').toLowerCase();
    return lcString.charAt(0).toUpperCase() + lcString.slice(1);
};
export const transformDataForHeatMap = <T extends Data>(
    data: ApiResponse<T>,
) => {
    const query = data.query;
    const annotation = data.annotation;
    const dimension = data.query.dimensions[0] || data.query.measures[0];

    const dimensionValues = data.data.reduce<(string | number | null)[]>(
        (acc, obj) => {
            if (obj[dimension] && !acc.includes(obj[dimension])) {
                acc.push(obj[dimension]);
            }
            return acc;
        },
        [],
    );
    const keys = [...query.measures, ...query.dimensions].filter(
        (key) => key !== dimension,
    );

    const transformedData: Serie[] = dimensionValues.map((rowName) => {
        const row = data.data.filter(
            (datum) => datum[dimension] === rowName,
        )[0];
        return {
            id: String(rowName),
            data: keys.map((key) => ({
                x: formatSqlString(key),
                y: row[key] ?? null,
            })),
        };
    });

    return {
        transformedData,
        query: query,
        annotation: annotation,
    };
};
