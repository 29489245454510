import ActionPanel from 'BackendRenderedComponents/actionPanel/ActionPanel';
import {AlphaToggleButton} from 'BackendRenderedComponents/alphaToggle/AlphaToggleButton';
import Banner from 'BackendRenderedComponents/banner/Banner';
import BasketPanel from 'BackendRenderedComponents/basket/BasketPanel';
// ==== BUTTONS ====
import {
    Button,
    ApplyDiscountButton,
    CancelButton,
    CheckoutDownloadInvoiceButton,
    CheckoutGoCardlessButton,
    ScaCheckoutStripeButton,
    CheckoutUpgradeButton,
    CopyButton,
    DeleteButton,
    DownloadFileButton,
    DownloadPDFFileButton,
    FormActionButton,
    LinkButton,
    LoadPageButton,
    MenuButton,
    OpenInNewTabButton,
    RefreshPageButton,
    RequestButton,
    SaveChangesButton,
    SystemModalButton,
    SystemNotificationButton,
    UploadButton,
    ScaCreditCardActionButton,
} from 'BackendRenderedComponents/button';
import {
    Chart,
    ChartToggle,
    ChartToggleContainer,
} from 'BackendRenderedComponents/chart';
import {ChatPanel} from 'BackendRenderedComponents/chatPanel';
import Checkout from 'BackendRenderedComponents/checkout/Checkout';
import CheckoutPaymentPanel from 'BackendRenderedComponents/checkout/CheckoutPaymentPanel';
import CheckoutPriceCalculator from 'BackendRenderedComponents/checkout/CheckoutPriceCalculator';
import CheckoutProperty from 'BackendRenderedComponents/checkout/CheckoutProperty';
import CheckoutWrapperMobile from 'BackendRenderedComponents/checkout/sca/CheckoutWrapperMobile';
import CheckoutWrapperMobileNew from 'BackendRenderedComponents/checkout/sca/CheckoutWrapperMobileNew';
// import {FormDownloadButton} from 'Components/button/formDownloadButton';
import {ColorPicker} from 'BackendRenderedComponents/colorPicker';
import {Combobox} from 'BackendRenderedComponents/combobox';
import {
    DashboardKpis,
    DashboardNotifications,
} from 'BackendRenderedComponents/dashboard';
import {
    DataControlPanel,
    DataControlPanelField,
} from 'BackendRenderedComponents/dataControlPanel';
import {DrillDown} from 'BackendRenderedComponents/drillDown';
import {DropdownMenu} from 'BackendRenderedComponents/dropdownMenu';
import {EventBox, EventBoxSection} from 'BackendRenderedComponents/EventBox';
import {Favourites} from 'BackendRenderedComponents/favourites';
import {FormFieldValueChecker} from 'BackendRenderedComponents/formFieldValueChecker';
import {GroupGlobalFilters} from 'BackendRenderedComponents/groupDashboardFilters';
import {HtmlEditor} from 'BackendRenderedComponents/htmlEditor';
import {Image} from 'BackendRenderedComponents/image';
import {InfoPanel} from 'BackendRenderedComponents/infoPanel/InfoPanel';
import {
    KpiPanelWithDataFetching,
    KpiPanelGroup,
} from 'BackendRenderedComponents/kpiPanel';
import {LayoutRow, LayoutColumn} from 'BackendRenderedComponents/layout';
import {RowColumnLayoutContainer} from 'BackendRenderedComponents/layout/RowColumnLayoutContainer';
import {LookerStudioBIDashboard} from 'BackendRenderedComponents/lookerStudioBIDashboard';
import {PowerBIDashboard} from 'BackendRenderedComponents/powerBIDashboard';
import {
    PurchasePanel,
    BundlePurchasePanel,
    PurchasePanelGroupProperty,
    PurchasePanelProperty,
} from 'BackendRenderedComponents/purchasePanel';
import {ResponsiveContainer} from 'BackendRenderedComponents/responsiveContainer';
import {PageToggle, SectionSelector} from 'BackendRenderedComponents/selectors';
import {ShortcutPanel} from 'BackendRenderedComponents/shortcutPanel';
import {SimpleTableContainer} from 'BackendRenderedComponents/simpleTableContainer';
import {SimpleText} from 'BackendRenderedComponents/simpleText';
import {Table} from 'BackendRenderedComponents/table';
import {VegaLiteChart} from 'BackendRenderedComponents/vegaLiteChart';
import {VennDiagram} from 'BackendRenderedComponents/vennDiagram';
import {CalendarWidget} from 'BackendRenderedComponents/widgets';

import {getExtComponentConstructor} from './extComponentUtils';

// It is important that these shared components get imported, as the other
// table related components are lazy loaded, and we need to make sure that
// these components are avaliable for both, else we get a chuck order error
//
// If we add more things to sharedTableSubComponents, it could potentially solve this,
// so we should try removing this.
import 'Components/sharedTableSubComponents';

// A list of all React components that can be specified by the backend twig-rendering pipeline
// Note: this is a function, to reduce the side effects when this file is imported in tests
const getReactComponentsMap = () => ({
    'Arbor.basket.BasketPanel': BasketPanel,
    'Arbor.button.ApplyDiscount': ApplyDiscountButton,
    'Arbor.button.Button': Button,
    'Arbor.button.Cancel': CancelButton,
    'Arbor.button.CheckoutDownloadInvoice': CheckoutDownloadInvoiceButton,
    'Arbor.button.CheckoutGoCardless': CheckoutGoCardlessButton,
    'Arbor.button.ScaCheckoutStripe': ScaCheckoutStripeButton,
    'Arbor.button.CheckoutUpgradeButton': CheckoutUpgradeButton, //Arbor.button.CheckoutUpgradeButton,
    'Arbor.button.Copy': CopyButton,
    'Arbor.button.Delete': DeleteButton,
    'Arbor.button.DownloadPDFFile': DownloadPDFFileButton,
    'Arbor.button.DownloadFile': DownloadFileButton,
    'Arbor.button.DropdownMenu': DropdownMenu,
    'Arbor.button.FormAction': FormActionButton,
    'Arbor.button.Link': LinkButton,
    'Arbor.button.LoadPage': LoadPageButton,
    'Arbor.button.MenuButton': MenuButton,
    'Arbor.button.OpenInNewTab': OpenInNewTabButton,
    'Arbor.button.RefreshPage': RefreshPageButton,
    'Arbor.button.Request': RequestButton,
    'Arbor.button.SaveChanges': SaveChangesButton,
    'Arbor.button.SystemNotification': SystemNotificationButton,
    'Arbor.button.SystemModal': SystemModalButton,
    'Arbor.button.Upload': UploadButton,
    'Arbor.button.AlphaToggle': AlphaToggleButton,
    'Arbor.button.ScaCreditCardAction': ScaCreditCardActionButton,
    'Arbor.chart.Chart': Chart,
    'Arbor.chart.ChartToggle': ChartToggle,
    'Arbor.chart.ChartToggleContainer': ChartToggleContainer,
    'Arbor.container.ActionPanel': ActionPanel,
    'Arbor.container.CheckoutWrapperMobile': CheckoutWrapperMobile,
    'Arbor.container.CheckoutWrapperMobileNew': CheckoutWrapperMobileNew,
    'Arbor.container.Banner': Banner,
    'Arbor.widget.ChatPanel': ChatPanel,
    'Arbor.container.Checkout': Checkout,
    'Arbor.container.CheckoutPaymentPanel': CheckoutPaymentPanel,
    'Arbor.container.CheckoutProperty': CheckoutProperty,
    'Arbor.container.CheckoutPriceCalculator': CheckoutPriceCalculator,
    'Arbor.container.Dashboard': DrillDown,
    'Arbor.container.DashboardKpis': DashboardKpis,
    'Arbor.container.DashboardNotifications': DashboardNotifications,
    'Arbor.formfield.FormFieldValueChecker': FormFieldValueChecker,
    'Arbor.formfield.ReactCombobox': Combobox,
    'Arbor.container.GroupGlobalFilters': GroupGlobalFilters,
    'Arbor.container.KpiPanelGroup': KpiPanelGroup,
    'Arbor.container.KpiPanel': KpiPanelWithDataFetching,
    'Arbor.container.NewKpiPanel': KpiPanelWithDataFetching,
    'Arbor.container.ResponsiveContainer': ResponsiveContainer,
    'Arbor.container.SimpleText': SimpleText,
    'Arbor.colorPicker.ColorPicker': ColorPicker,
    'Arbor.image.Image': Image,
    'Arbor.purchase.BundlePurchasePanel': BundlePurchasePanel,
    'Arbor.purchase.PurchasePanel': PurchasePanel,
    'Arbor.purchase.PurchasePanelGroupProperty': PurchasePanelGroupProperty,
    'Arbor.purchase.PurchasePanelProperty': PurchasePanelProperty,
    'Arbor.selector.PageToggle': PageToggle,
    'Arbor.selector.SectionSelector': SectionSelector,
    'Arbor.table.ReactTable': Table,
    'Arbor.table.SimpleTableContainer': SimpleTableContainer,
    'Arbor.widget.ShortcutPanel': ShortcutPanel,
    'Arbor.widget.Calendar': CalendarWidget,
    'Arbor.widget.Favourites': Favourites,
    'Arbor.container.EventBox': EventBox,
    'Arbor.container.EventBoxSection': EventBoxSection,
    'Arbor.formfield.ReactHtmlEditor': HtmlEditor,
    'Arbor.container.InfoPanel': InfoPanel,
    'Arbor.container.DataControlPanel': DataControlPanel,
    'Arbor.container.DataControlPanelField': DataControlPanelField,
    'Arbor.container.PowerBiDashboard': PowerBIDashboard,
    'Arbor.container.LookerStudioBIDashboard': LookerStudioBIDashboard,
    'Arbor.container.RowColumnLayoutContainer': RowColumnLayoutContainer,
    'Arbor.container.LayoutRow': LayoutRow,
    'Arbor.container.LayoutColumn': LayoutColumn,
    'Arbor.container.VennDiagram': VennDiagram,
    'Arbor.container.VegaLiteChart': VegaLiteChart,
});

// A list of all Ext components that can be specified by the backend twig-rendering pipeline
const extComponents = [
    'Arbor.attendance.AttendanceCoreMarkModel',
    'Arbor.attendance.AttendanceMarkModel',
    'Arbor.attendance.AttendanceModel',
    'Arbor.attendance.AttendancePanel',
    'Arbor.attendance.AttendancePopup',
    'Arbor.attendance.AttendanceProfilePanel',
    'Arbor.attendance.AttendanceProfilePanelView',
    'Arbor.button.AddRow',
    'Arbor.button.FormDownload',
    'Arbor.button.FormMerge',
    'Arbor.button.GroupedButtonTable',
    'Arbor.button.GroupedButtonTableDownload',
    'Arbor.button.LegacyLoadPage',
    'Arbor.calendar.Abstract',
    'Arbor.calendar.Calendar',
    'Arbor.container.CommunicationCenter',
    'Arbor.container.ContentVisibility',
    'Arbor.container.Dropzone',
    'Arbor.container.FormFieldContainer',
    'Arbor.container.GMapPanel',
    'Arbor.container.GroupedButtons',
    'Arbor.container.HtmlContainer',
    'Arbor.container.IncorrectContainerFallback',
    'Arbor.container.LayoutContainer',
    'Arbor.container.ListRow',
    'Arbor.container.Partial',
    'Arbor.container.PropertyRow',
    'Arbor.container.RowTreePanel',
    'Arbor.container.RowTreeView',
    'Arbor.container.Section',
    'Arbor.container.SectionView',
    'Arbor.container.ShowAll',
    'Arbor.container.SubSection',
    'Arbor.data.BaseMenu',
    'Arbor.data.Model',
    'Arbor.data.Store',
    'Arbor.data.proxy.Proxy',
    'Arbor.data.reader.Json',
    'Arbor.data.writer.Json',
    'Arbor.dataview.DataView',
    'Arbor.filter.FilterField',
    'Arbor.filter.FilterFieldCheckbox',
    'Arbor.filter.FilterFieldGroup',
    'Arbor.filter.FilterFieldGroupCombobox',
    'Arbor.filter.FilterFieldSearch',
    'Arbor.filter.FilterFieldStudent',
    'Arbor.filter.FilterFieldStudentCombobox',
    'Arbor.filter.FilterPanel',
    'Arbor.filter.FilterPanelDisplay',
    'Arbor.formfield.Checkbox',
    'Arbor.formfield.CheckboxGroup',
    'Arbor.formfield.Combobox',
    'Arbor.formfield.Currency',
    'Arbor.formfield.Date',
    'Arbor.formfield.DateRange',
    'Arbor.formfield.DateTime',
    'Arbor.formfield.DateTimeTrigger',
    'Arbor.formfield.Duration',
    'Arbor.formfield.Password',
    'Arbor.formfield.Radio',
    'Arbor.formfield.RadioGroup',
    'Arbor.formfield.TagField',
    'Arbor.formfield.TextArea',
    'Arbor.formfield.Time',
    'Arbor.gridlist.GridList',
    'Arbor.menu.Item',
    'Arbor.menu.Menu',
    'Arbor.model.ComboboxModel',
    'Arbor.model.FileModel',
    'Arbor.model.GridListModel',
    'Arbor.model.RowTreeModel',
    'Arbor.model.SimpleComboboxModel',
    'Arbor.picker.DatePicker',
    'Arbor.picker.DateTimePicker',
    'Arbor.picker.MonthPicker',
    'Arbor.picker.TimePicker',
    'Arbor.searchablelist.SearchableList',
    'Arbor.selector.Selector',
    'Arbor.tab.Panel',
    'Arbor.tab.Tab',
    'Arbor.toolbar.PagingToolbar',
    'Arbor.tooltip.Tooltip',
    'Arbor.widget.Attachments',
    'Arbor.widget.Widget',
    'Arbor.window.Window',
];

// A list of Ext components that have been overriden with custom MIS functionality.
// Note: once an Ext component is overridden, the override is used everywhere.
// Note: 2 of these are also in extNativeComponents. This doesn't matter too much other
// than causing a bit of confusion. It just means that there are 2 possible names that
// the backend can use for the same component.
const extOverrides = [
    {path: 'Arbor.Component', extPath: 'Ext.Component'},
    {path: 'Arbor.window.MessageBox', extPath: 'Ext.window.MessageBox'},
];

// A list of all Ext Native components that can be specified by the backend twig-rendering pipeline
// Ext native refers to core Ext components that have not been customised.
// TODO [MIS-38616]: 3 of these components are actually custom arbor componetns that should not have
// a path of Arbor.ExtNative.x so we should investigate this and clean it up.
const extNativeComponents = [
    {path: 'Arbor.ExtNative.Container', extPath: 'Ext.container.Container'},
    {path: 'Arbor.ExtNative.Hidden', extPath: 'Ext.form.field.Hidden'},
    {path: 'Arbor.ExtNative.DisplayField', extPath: 'Ext.form.field.Display'},
    {path: 'Arbor.ExtNative.NumberField', extPath: 'Ext.form.field.Number'},
    {path: 'Arbor.ExtNative.FileField', extPath: 'Ext.form.field.File'},
    {path: 'Arbor.ExtNative.TextField', extPath: 'Ext.form.field.Text'},
    {path: 'Arbor.ExtNative.RadioGroup', extPath: 'Ext.form.RadioGroup'},
    {path: 'Arbor.ExtNative.Label', extPath: 'Ext.form.Label'},
    {path: 'Arbor.ExtNative.MenuSeparator', extPath: 'Ext.menu.Separator'},
    {path: 'Arbor.ExtNative.Panel', extPath: 'Ext.panel.Panel'},
];

export const generateExtComponentCache = (
    extComponentsArray: string[],
    extOverridesArray: {path: string; extPath: string}[],
    extNativeComponentsArray: {path: string; extPath: string}[],
): Record<string, number> => ({
    ...extComponentsArray.reduce(
        (result, value) => ({
            ...result,
            [value]: getExtComponentConstructor(
                value.replace(/^Arbor\./, 'Mis.'),
            ),
        }),
        {},
    ),
    ...extOverridesArray.reduce(
        (result, {path, extPath}) => ({
            ...result,
            [path]: getExtComponentConstructor(extPath),
        }),
        {},
    ),
    ...extNativeComponentsArray.reduce(
        (result, {path, extPath}) => ({
            ...result,
            [path]: getExtComponentConstructor(extPath),
        }),
        {},
    ),
});

// Note: if accessed by the componentCache, the Ext components are resolved at runtime by the
// ExtComponentRenderer component
const extComponentCache = generateExtComponentCache(
    extComponents,
    extOverrides,
    extNativeComponents,
);

export const getComponentContructor = (key: string) => {
    if (extComponentCache[key]) {
        return extComponentCache[key];
    }
    if (getReactComponentsMap()[key]) {
        return getReactComponentsMap()[key];
    }
    throw new Error('No component for key ' + key);
};

export const isExtComponent = (key) => {
    return !!extComponentCache[key];
};
