// Notes on classnames: Class `no-print` removes the element when printing directly from the browser (e.g. cia Cmd + P). Class `no-export` removes the element when exporting to PDF
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import classNames from 'classnames';
import {
    useEffect,
    useState,
    useRef,
    useLayoutEffect,
    MutableRefObject,
} from 'react';

import {Button} from 'BackendRenderedComponents/button';
import {ChatPanel} from 'BackendRenderedComponents/chatPanel';
import {LoadingWidget} from 'BackendRenderedComponents/chatPanel/LoadingWidget';
import {BarChart} from 'BackendRenderedComponents/responsiveChartingDashboard/Charts/BarChart';
import {AskArborLogo} from 'Components/button/base/button/AskArborLogo';
import {HtmlEditor} from 'Components/htmlEditor';
import {
    getAgentNotificationService,
    AgentNotificationSubscriptionDetails,
} from 'Services/agentNotification/AgentNotification';

import {ChatMessage} from '../chatPanel/ChatPanel';

import {getHistoricCanvas} from './getHistoricCanvas';
import {CanvasHtmlTable} from './render/CanvasHtmlTable';
import {CanvasTable} from './render/CanvasTable';
import {ExportType, postHtmlExport} from './utils/postHtmlExport';
import {transformRequestDataToChartData} from './utils/transformRequestDataToChartData';

import './ChatCanvas.scss';

export interface CanvasElement {
    id: string;
    type?: string;
    title?: string;
    contentType: 'HTML' | 'CHART' | 'TABLE';
    contentChartType?: string;
    content: any;
    visible?: boolean;
    contentChartGrouping?: 'grouped' | 'stacked';
    editedOrCreatedByUser: boolean;
    svg?: string;
}

type CanvasProps = {
    chatUrl: string;
    pusherChannel?: string;
    exportUrl?: string;
    historicCanvasUrl?: string;
};

const MESSAGE_TYPE_PROGRESS_UPDATE = 'MESSAGE_TYPE_PROGRESS_UPDATE';
const MESSAGE_TYPE_FINAL = 'MESSAGE_TYPE_FINAL';
const MESSAGE_TYPE_CLEAR_DOWN = 'MESSAGE_TYPE_CLEAR_DOWN';

const CONTENT_TYPE = {
    CHART: 'CHART' as const,
    TABLE: 'TABLE' as const,
    HTML: 'HTML' as const,
};

const queryClient = new QueryClient();

export const ChatCanvas = ({
    chatUrl,
    pusherChannel = '',
    exportUrl = '',
    historicCanvasUrl,
}: CanvasProps) => {
    const [progressMessage, setProgressMessage] = useState<string | null>(null);
    const [canvasContent, setCanvasContent] = useState<CanvasElement[]>([]);
    const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
    const elementRef = useRef<HTMLDivElement>(
        null,
    ) as MutableRefObject<HTMLDivElement | null>;

    const [manualInteraction, setManualInteraction] = useState(false); // This handles the case where, if the user has manually interacted with the canvas, we should not scroll to the bottom
    const [titleBeingEdited, setTitleBeingEdited] = useState<number | null>(
        null,
    );
    const [contentBeingEdited, setContentBeingEdited] = useState<number | null>(
        null,
    );
    const htmlEditorRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            // If clicked inside .canvas__wrapper but not inside the HTML editor, close the editor
            const target = event.target as HTMLElement;
            const isInsideWrapper = target.closest('.canvas__wrapper');
            const isInsideHtmlEditor = target.closest(
                '.canvas__element-individual-container-html-editor',
            );

            if (isInsideWrapper && !isInsideHtmlEditor) {
                setContentBeingEdited(null);
            }
        };

        const handleEscape = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setTitleBeingEdited(null);
                setContentBeingEdited(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleEscape);

        return () => {
            // Cleanup event listeners on component unmount
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleEscape);
        };
    }, []);

    useEffect(() => {
        const fetchHistoricData = async () => {
            if (historicCanvasUrl) {
                setProgressMessage('Loading Canvas...');
                const {chatHistory: fetchedChatHistory, canvasHistory} =
                    await getHistoricCanvas(historicCanvasUrl);
                setChatHistory(fetchedChatHistory);
                setCanvasContent(canvasHistory);
                setProgressMessage(null);
            }
        };
        fetchHistoricData();
    }, [historicCanvasUrl]);

    const addCanvasElement = (element: CanvasElement) => {
        setManualInteraction(false);
        setCanvasContent((prev) => {
            const existingIndex = prev.findIndex(
                (msg) => msg.id === element.id,
            );
            if (existingIndex !== -1) {
                // Overwrite an existing element, if it has the same `id`
                const updatedElements = [...prev];
                updatedElements[existingIndex] = {
                    ...prev[existingIndex],
                    ...element,
                    editedOrCreatedByUser: false,
                    visible:
                        typeof element.visible === 'boolean'
                            ? element.visible
                            : true,
                };
                return updatedElements;
            }

            return [
                ...prev,
                {
                    ...element,
                    editedOrCreatedByUser: false,
                    visible:
                        typeof element.visible === 'boolean'
                            ? element.visible
                            : true,
                },
            ];
        });
    };

    // Subscribe to agent notifications
    useEffect(() => {
        const agentNotificationService = getAgentNotificationService();
        agentNotificationService.init({pushChannel: pusherChannel});

        const handleMessage = (message: CanvasElement) => {
            if (message.type === MESSAGE_TYPE_PROGRESS_UPDATE) {
                setProgressMessage(message.content || '');
            } else if (message.type === MESSAGE_TYPE_CLEAR_DOWN) {
                setProgressMessage(null);
            } else if (message.type === MESSAGE_TYPE_FINAL) {
                if (message.contentType === CONTENT_TYPE.CHART) {
                    const transformedMessage = {
                        ...message,
                        content: transformRequestDataToChartData(
                            message.content,
                        ),
                    };
                    addCanvasElement(transformedMessage);
                } else {
                    addCanvasElement(message);
                }
                setProgressMessage(null);
            }
        };

        const pusherSubscription: AgentNotificationSubscriptionDetails =
            agentNotificationService.subscribe(
                (payload: CanvasElement | CanvasElement[]) => {
                    if (Array.isArray(payload)) {
                        payload.forEach(handleMessage);
                    } else {
                        handleMessage(payload);
                    }
                },
            );
        const unsubscribe = agentNotificationService.unsubscribe;
        return () => {
            unsubscribe(pusherSubscription?.subscriptionId);
            agentNotificationService.init({pushChannel: ''});
        };
    }, [pusherChannel]);

    // Scroll to bottom whenever new elements are added, but ONLY if the user hasn't interacted manually.
    useLayoutEffect(() => {
        if (!manualInteraction && elementRef.current) {
            elementRef.current.scrollTo({
                top: elementRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }
    }, [canvasContent, manualInteraction]);

    const toggleElementVisibility = (index: number) => {
        setManualInteraction(true); // Turn off auto-scroll
        setCanvasContent((prev) => {
            const updated = [...prev];
            updated[index] = {
                ...updated[index],
                visible: !updated[index].visible,
            };
            return updated;
        });
    };

    const removeElement = (index: number) => {
        setManualInteraction(true); // Turn off auto-scroll
        setCanvasContent((prev) => prev.filter((_, i) => i !== index));
    };

    const increaseElementOrder = (index: number) => {
        setManualInteraction(true);
        setCanvasContent((prev) => {
            const updated = [...prev];
            const element = updated.splice(index, 1)[0];
            updated.splice(index + 1, 0, element);
            return updated;
        });
    };

    const decreaseElementOrder = (index: number) => {
        setManualInteraction(true);
        setCanvasContent((prev) => {
            const updated = [...prev];
            const element = updated.splice(index, 1)[0];
            updated.splice(index - 1, 0, element);
            return updated;
        });
    };

    const addBlankHtmlElement = () => {
        setCanvasContent((prev) => [
            ...prev,
            {
                id: `element-${Date.now()}`,
                content: 'Content',
                contentType: CONTENT_TYPE.HTML,
                title: 'Title',
                visible: true,
                editedOrCreatedByUser: true,
            } as CanvasElement,
        ]);
    };

    const updateElementTitle = (id: string, newTitle: string) => {
        setCanvasContent((prev) =>
            prev.map((msg) =>
                msg.id === id ? {...msg, title: newTitle} : msg,
            ),
        );
    };

    const handleTitleClick = (index: number) => {
        setManualInteraction(true);
        setTitleBeingEdited(index);
    };

    const handleTitleChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        id: string,
    ) => {
        const newTitle = e.target.value;
        updateElementTitle(id, newTitle);
    };

    const handleTitleBlur = () => {
        setTitleBeingEdited(null);
    };

    const handleTitleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        setManualInteraction(true);
        if (e.key === 'Enter') {
            setTitleBeingEdited(null);
        } else if (e.key === 'Escape') {
            setTitleBeingEdited(null);
        }
    };

    const handleHtmlContentClick = (index: number) => {
        setManualInteraction(true);
        setTitleBeingEdited(null);
        setContentBeingEdited(index);
    };

    const handleHtmlContentChange = (index: number, html: string) => {
        setManualInteraction(true);

        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');

        // Ensure that all links open in a new tab, otherwise the user will be navigated away from Canvas and loose their work
        const links = doc.querySelectorAll('a');

        // Check if the link already has target="_blank"
        links.forEach((link) => {
            const currentTarget = link.getAttribute('target');
            if (currentTarget !== '_blank') {
                link.setAttribute('target', '_blank');
                link.setAttribute('rel', 'noopener noreferrer'); // For security reasons
            }
        });

        const updatedHtml = doc.body.innerHTML;
        setCanvasContent((prev) =>
            prev.map((elem, i) =>
                i === index
                    ? {
                          ...elem,
                          editedOrCreatedByUser: true,
                          content: updatedHtml,
                      }
                    : elem,
            ),
        );
    };

    /**
     * This function is a generic handler for exporting the canvas.
     * You can specify a type of export via the `exportType` parameter.The backend will handle the export based on the type.
     * It clones the canvas, removes elements with the "no-export" class, converts SVGs to base64 images, and downloads the canvas as a PDF.
     * It offers the backend either a HTML string or a JSON string, depending on the backend's requirements.
     * This is required as we move from a basic HTML PDF to word documents and persisting the canvas.
     * @returns {Promise<void>}
     */
    const handleExport = async (exportType: ExportType = 'pdf') => {
        // TODO: Abstract this to a service
        if (!elementRef.current) return;
        setTitleBeingEdited(null);
        const clonedHtml = elementRef.current.cloneNode(true) as HTMLElement;

        // Utility function to remove elements by selector
        const removeElementsBySelector = (
            container: HTMLElement,
            selector: string,
        ) => {
            const elements = container.querySelectorAll(selector);
            elements.forEach((element) => element.remove());
        };

        // Utility function to convert SVG to base64 image
        const replaceSvgWithImage = (svg: SVGElement) => {
            const serializer = new XMLSerializer();
            const svgString = serializer.serializeToString(svg);
            const base64 = btoa(unescape(encodeURIComponent(svgString)));

            const img = document.createElement('img');
            img.src = `data:image/svg+xml;base64,${base64}`;
            img.style.width = '100%';
            img.style.height = 'auto';
            img.classList.add('canvas__img-for-export');
            svg.replaceWith(img);
        };

        removeElementsBySelector(clonedHtml, '.no-export'); // Remove elements with "no-export" class

        // Convert all SVG elements to base64 encoded images
        const svgElements = clonedHtml.querySelectorAll('svg');
        svgElements.forEach(replaceSvgWithImage);

        const cleanedHtmlContent = clonedHtml.outerHTML;

        // Include SVGs as part of the JSON payload
        // This is necessary because the SVGs are required for the backend to render the chart images
        const canvasContentWithChartSvgs = canvasContent.map((element) => {
            if (element.contentType !== CONTENT_TYPE.CHART) return element;

            const clonedElement = {...element};
            const chartContainer = document.createElement('div');
            chartContainer.innerHTML = element.content;

            const svgElement = chartContainer.querySelector('svg');
            if (svgElement) {
                const serializer = new XMLSerializer();
                const svgString = serializer.serializeToString(svgElement);
                const base64 = btoa(unescape(encodeURIComponent(svgString)));
                clonedElement.svg = `data:image/svg+xml;base64,${base64}`;
            }

            return clonedElement;
        });

        if (exportUrl) {
            const progressResult = await postHtmlExport({
                exportUrl,
                rawHtml: cleanedHtmlContent,
                json: JSON.stringify(canvasContentWithChartSvgs),
                canvasId: pusherChannel,
                exportType,
            });

            setProgressMessage(
                progressResult
                    ? null
                    : "Whoops! Something didn't work. Please try again.",
            );
        }
    };

    const handleSave = () => {
        setProgressMessage('Saving...');
        handleExport('save');
        setProgressMessage('Saved!');
    };

    const handleDownloadAsPdf = () => {
        setProgressMessage('Downloading...');
        handleExport('pdf');
    };

    const handleNewCanvas = () => {
        const baseUrl = window.location.origin;
        const redirectUrl = `${baseUrl}/?/ask-arbor-ui/canvas`; // TODO: This should be a prop
        window.location.href = redirectUrl;
    };

    return (
        <QueryClientProvider client={queryClient}>
            <div className="canvas__wrapper">
                <div className="canvas__chat-panel-container">
                    <ChatPanel
                        canvasMode={true}
                        chatUrl={chatUrl}
                        placeholder="What would you like to know?"
                        canvasPusherChannel={pusherChannel}
                        canvasHistory={canvasContent}
                        chatHistory={chatHistory}
                    />
                </div>

                <div className="canvas__container">
                    <div className="canvas__header">
                        <div className="canvas__header-progress-messages">
                            {progressMessage ? (
                                <LoadingWidget
                                    loadingPhrase={progressMessage}
                                    showSpinnerAsIcon={true}
                                />
                            ) : (
                                <>
                                    <div className="canvas__header-ask-arbor-logo">
                                        <AskArborLogo />
                                        {canvasContent.length === 0 && (
                                            <div className="canvas__header__placeholder">
                                                {/* Placeholder text */}
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="canvas__header-actions">
                            {canvasContent.length > 0 && (
                                <>
                                    <Button
                                        onClick={handleNewCanvas}
                                        icon="plus"
                                        color="grey"
                                        ariaLabel="New Canvas"
                                        tooltipHTML="New canvas"
                                        className="canvas__header-new-canvas-button"
                                        text="New"
                                    />
                                    <Button
                                        onClick={handleSave}
                                        icon="file"
                                        color="grey"
                                        ariaLabel="Save"
                                        tooltipHTML="Save this canvas"
                                        className="canvas__header-print-button"
                                        text="Save"
                                    />
                                    <Button
                                        onClick={handleDownloadAsPdf}
                                        icon="download"
                                        color="grey"
                                        ariaLabel="Download as PDF"
                                        tooltipHTML="Download the canvas as a PDF"
                                        className="canvas__header-download-button"
                                        text="Download"
                                    />
                                </>
                            )}
                        </div>
                    </div>
                    <div className="canvas__element-container" ref={elementRef}>
                        {canvasContent.length === 0 ? (
                            // TODO: Add some placeholder content in here
                            <></>
                        ) : (
                            <>
                                {canvasContent.map((element, index) => (
                                    <div
                                        key={`element-${element.id}`}
                                        className={classNames(
                                            'canvas__element-individual-container',
                                            {
                                                'no-print no-export':
                                                    !element.visible,
                                            },
                                        )}
                                    >
                                        <div className="canvas__element-individual-container-header">
                                            <div className="canvas__element-individual-container-header-title">
                                                {titleBeingEdited === index ? (
                                                    <input
                                                        type="text"
                                                        value={element.title}
                                                        onChange={(e) =>
                                                            handleTitleChange(
                                                                e,
                                                                element.id,
                                                            )
                                                        }
                                                        onBlur={handleTitleBlur}
                                                        onKeyDown={
                                                            handleTitleKeyDown
                                                        }
                                                        autoFocus
                                                        className="canvas__element-title-input canvas__element-title-common no-export"
                                                        aria-label="Edit message title"
                                                    />
                                                ) : (
                                                    <h3
                                                        className="canvas__element-title canvas__element-title-common"
                                                        onClick={() =>
                                                            handleTitleClick(
                                                                index,
                                                            )
                                                        }
                                                        title="Click to edit title"
                                                    >
                                                        {element.title || ' '}
                                                    </h3>
                                                )}
                                            </div>
                                            <Button
                                                onClick={() =>
                                                    removeElement(index)
                                                }
                                                color="red"
                                                icon="delete"
                                                ariaLabel="Remove"
                                                tooltipHTML="Remove."
                                                className="canvas__element-delete-button noprint no-export"
                                            />
                                            <Button
                                                onClick={() =>
                                                    decreaseElementOrder(index)
                                                }
                                                icon="up"
                                                ariaLabel="Move up"
                                                tooltipHTML="Move up."
                                                className="canvas__element-move-down-button noprint no-export"
                                                disabled={index === 0}
                                            />
                                            <Button
                                                onClick={() =>
                                                    increaseElementOrder(index)
                                                }
                                                icon="down"
                                                ariaLabel="Move down"
                                                tooltipHTML="Move down."
                                                className="canvas__element-move-up-button noprint no-export"
                                                disabled={
                                                    index ===
                                                    canvasContent.length - 1
                                                }
                                            />
                                            <Button
                                                onClick={() =>
                                                    toggleElementVisibility(
                                                        index,
                                                    )
                                                }
                                                icon={
                                                    element.visible
                                                        ? 'minus'
                                                        : 'plus'
                                                }
                                                ariaLabel={
                                                    element.visible
                                                        ? 'Hide'
                                                        : 'Show'
                                                }
                                                tooltipHTML={
                                                    element.visible
                                                        ? 'Hide'
                                                        : 'Show'
                                                }
                                                className="canvas__element-toggle-button no-export"
                                            />
                                        </div>
                                        <hr className="canvas__element-container-hr" />

                                        {element.visible && (
                                            <>
                                                {element.contentType ===
                                                    CONTENT_TYPE.CHART && (
                                                    <div className="canvas__element-individual-container canvas__element-individual-container-chart">
                                                        {element.contentChartType ===
                                                            'bar' &&
                                                            element.content &&
                                                            Array.isArray(
                                                                element.content
                                                                    .data,
                                                            ) &&
                                                            element.content.data
                                                                .length > 0 && (
                                                                <BarChart
                                                                    key={`element-barchart-${element.id}`}
                                                                    data={
                                                                        element.content
                                                                    }
                                                                    grouped={
                                                                        element.contentChartGrouping ===
                                                                        'grouped'
                                                                    }
                                                                    xAxisLabel=" "
                                                                />
                                                            )}
                                                    </div>
                                                )}

                                                {element.contentType ===
                                                    CONTENT_TYPE.TABLE && (
                                                    <div className="canvas__element-individual-container canvas__element-individual-container-table">
                                                        <CanvasHtmlTable
                                                            id={element.id}
                                                            content={
                                                                element.content
                                                            }
                                                            key={`element-table-html-${element.id}`}
                                                            className="hidden-export-table"
                                                        />
                                                        <div className="no-export">
                                                            <CanvasTable
                                                                id={element.id}
                                                                key={`element-table-${element.id}`}
                                                                content={
                                                                    element.content
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                )}

                                                {(!element.contentType ||
                                                    element.contentType ===
                                                        CONTENT_TYPE.HTML) && (
                                                    <div
                                                        className="canvas__element-individual-container canvas__element-individual-container-html-editor"
                                                        ref={
                                                            htmlEditorRef // The ref is used to track the users clicks outside the element, this then closes the Html editor and replaces it with raw HTML
                                                        }
                                                    >
                                                        {contentBeingEdited ===
                                                        index ? (
                                                            <div
                                                                tabIndex={0}
                                                                contentEditable={
                                                                    false
                                                                }
                                                                className="canvas__element-html-editor-container"
                                                            >
                                                                <HtmlEditor
                                                                    value={
                                                                        element.content ||
                                                                        ''
                                                                    }
                                                                    onChange={(
                                                                        val,
                                                                    ) =>
                                                                        handleHtmlContentChange(
                                                                            index,
                                                                            val,
                                                                        )
                                                                    }
                                                                    autofocus
                                                                    height={392}
                                                                    enableFontSize={
                                                                        true
                                                                    }
                                                                    enableTables={
                                                                        true
                                                                    }
                                                                    enableLinks={
                                                                        true
                                                                    }
                                                                    enableLists={
                                                                        true
                                                                    }
                                                                    enableImage={
                                                                        true
                                                                    }
                                                                />
                                                            </div>
                                                        ) : (
                                                            <span
                                                                id={`canvas__element-${element.id}`}
                                                                onClick={() =>
                                                                    handleHtmlContentClick(
                                                                        index,
                                                                    )
                                                                }
                                                                tabIndex={0}
                                                                key={`element-element-${element.id}`}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: element.content,
                                                                }}
                                                            />
                                                        )}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                ))}
                                <div className="canvas__elements-footer">
                                    <div className="canvas__elements-footer-container">
                                        <Button
                                            onClick={() =>
                                                addBlankHtmlElement()
                                            }
                                            icon="plus"
                                            color="green"
                                            ariaLabel="Add new content"
                                            tooltipHTML="Add new content."
                                            className="canvas__element-add-button no-export"
                                            text="Add"
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </QueryClientProvider>
    );
};
