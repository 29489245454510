import {httpGet} from 'Interfaces/httpClient';

export const fetchDashboardItemData = async (queryKey) => {
    // need chartQueryKey here for tanstack
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [chartQueryKey, dataQuery] = queryKey;
    const {dataUrl, params} = dataQuery;
    const response = await httpGet(dataUrl, params);

    return response;
};
