import {useEffect, useRef, useState} from 'react';

import {fastWeakEncode} from 'Interfaces/Hash';
import {httpGet} from 'Interfaces/httpClient';
import reportToSisSentry from 'Interfaces/raven/Raven';
import {useComponentDidMount} from 'Root/core/utils/useComponentDidMount';

import {REPORTING_DASHBOARD_SESSION_STORAGE_KEY} from './constants';
import {ReportData} from './ReportingDashboard';
import {isCustomReportHelper} from './utils';

const getUniqueCategories = (reports: ReportData[]) => {
    const allCategories: string[] = [];
    reports.forEach((report) =>
        allCategories.push(...(report.categories || [])),
    );
    allCategories.sort();
    return ['All', ...new Set(allCategories)].filter(
        (category) => category !== null,
    );
};

const sanitiseData = (dirtyResponse: ReportData[]) => {
    return dirtyResponse
        .filter((thisReport, index) => {
            return (
                dirtyResponse.findIndex(
                    (thatReport) =>
                        JSON.stringify(thisReport) ===
                        JSON.stringify(thatReport),
                ) === index
            );
        })
        .map((report) => {
            const keyHash = `${fastWeakEncode(JSON.stringify(report))}__${
                report.url
            }`;
            return {...report, keyHash};
        });
};

type SavedReportFilters = {
    searchQuery?: string;
    selectedCategory?: string;
    showArborReports?: boolean;
    showCustomReports?: boolean;
    showOnlyMyCustomReports?: boolean;
};

export const useReportsData = (fetchUrl: string) => {
    const savedFilterStateRef = useRef<SavedReportFilters>(
        JSON.parse(
            sessionStorage.getItem(REPORTING_DASHBOARD_SESSION_STORAGE_KEY) ||
                '{}',
        ),
    );

    const [reportsList, setReportsList] = useState<ReportData[]>([]);
    const [filteredReports, setFilteredReports] = useState<ReportData[]>([]);
    const [categories, setCategories] = useState<string[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>(
        () => savedFilterStateRef.current.searchQuery || '',
    );
    const [selectedCategory, setSelectedCategory] = useState<string>(
        () => savedFilterStateRef.current.selectedCategory || 'All',
    );
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    const [showArborReports, setShowArborReports] = useState<boolean>(
        savedFilterStateRef.current.showArborReports ?? true,
    );
    const [showCustomReports, setShowCustomReports] = useState<boolean>(
        savedFilterStateRef.current.showCustomReports ?? true,
    );
    const [showOnlyMyCustomReports, setShowOnlyMyCustomReports] =
        useState<boolean>(
            savedFilterStateRef.current.showOnlyMyCustomReports ?? false,
        );

    useComponentDidMount(() => {
        setIsLoading(true);
        httpGet(fetchUrl)
            .then((dirtyResponse) => {
                const response = sanitiseData(dirtyResponse);
                setReportsList(response);
                setFilteredReports(response);
                setCategories(getUniqueCategories(response));
            })
            .catch((response) => {
                if (!response || response.status !== 401) {
                    reportToSisSentry(new Error('Error fetching report list'), {
                        response,
                    });
                }
                console.error(response);
                setHasError(true);
            })
            .finally(() => {
                setIsLoading(false);
            });
    });

    useEffect(() => {
        const safeSearchQuery = searchQuery.trim().toLowerCase();
        let filteredList = reportsList;
        // Find out what the origin of the report is, then ask is that one of the allowed origins
        filteredList = filteredList.filter((report) => {
            if (isCustomReportHelper(report)) {
                if (showOnlyMyCustomReports) {
                    return report.isCreatedByCurrentUser;
                }
                return showCustomReports;
            }
            return showArborReports;
        });
        if (selectedCategory !== 'All') {
            filteredList = filteredList.filter((report) => {
                return report.categories?.includes(selectedCategory);
            });
        }
        if (safeSearchQuery) {
            filteredList = filteredList.filter(
                (report) =>
                    report.name.toLowerCase().includes(safeSearchQuery) ||
                    report.description?.toLowerCase().includes(safeSearchQuery),
            );
        }

        setFilteredReports(filteredList);
    }, [
        selectedCategory,
        searchQuery,
        reportsList,
        showArborReports,
        showOnlyMyCustomReports,
        showCustomReports,
    ]);

    useEffect(() => {
        savedFilterStateRef.current = {
            searchQuery,
            selectedCategory,
            showArborReports,
            showCustomReports,
            showOnlyMyCustomReports,
        };
        sessionStorage.setItem(
            REPORTING_DASHBOARD_SESSION_STORAGE_KEY,
            JSON.stringify(savedFilterStateRef.current),
        );
    }, [
        selectedCategory,
        searchQuery,
        showArborReports,
        showCustomReports,
        showOnlyMyCustomReports,
    ]);

    return {
        reportsList,
        filteredReports,
        categories,
        setSearchQuery,
        selectedCategory,
        setSelectedCategory,
        isLoading,
        hasError,
        searchQuery,
        showArborReports,
        showCustomReports,
        showOnlyMyCustomReports,
        setShowArborReports,
        setShowCustomReports,
        setShowOnlyMyCustomReports,
    };
};
