import {ReportingDashboardHeader} from 'BackendRenderedComponents/reportingDashboard/ReportingDashboardHeader';
import {Card} from 'Components/card';
import {FlexGridContainer} from 'Components/flexGridContainer';
import {LoadingSpinner} from 'Components/loadingSpinner';

import {useReportsData} from './useReportsData';
import {isCustomReportHelper} from './utils';

import './reportingDashboard.scss';

type ReportingDashboardProps = {
    fetchUrl: string;
};

export type ReportData = {
    keyHash?: string;
    categories?: string[];
    description?: string;
    name: string;
    parameters?;
    url: string;
    isCreatedByCurrentUser?: boolean;
};

export const ReportingDashboard = (props: ReportingDashboardProps) => {
    const {fetchUrl} = props;

    const {
        reportsList,
        filteredReports,
        categories,
        setSearchQuery,
        selectedCategory,
        setSelectedCategory,
        isLoading,
        hasError,
        searchQuery,
        showArborReports,
        showCustomReports,
        showOnlyMyCustomReports,
        setShowArborReports,
        setShowCustomReports,
        setShowOnlyMyCustomReports,
    } = useReportsData(fetchUrl);

    if (hasError) {
        return <p>Error fetching report list</p>;
    }

    return (
        <div className="reporting-dashboard__container">
            <ReportingDashboardHeader
                isLoading={isLoading}
                categories={categories}
                selectedCategory={selectedCategory}
                setSearchQuery={setSearchQuery}
                searchQuery={searchQuery}
                setSelectedCategory={setSelectedCategory}
                displayCount={filteredReports.length}
                totalCount={reportsList.length}
                showArborReports={showArborReports}
                showCustomReports={showCustomReports}
                showOnlyMyCustomReports={showOnlyMyCustomReports}
                setShowArborReports={setShowArborReports}
                setShowCustomReports={setShowCustomReports}
                setShowOnlyMyCustomReports={setShowOnlyMyCustomReports}
            />
            {!isLoading ? (
                <FlexGridContainer>
                    {filteredReports.map((report: ReportData) => {
                        const isCustomReport = isCustomReportHelper(report);
                        return (
                            <Card
                                key={report.keyHash}
                                title={report.name}
                                paragraph={report.description}
                                url={report.url}
                                pillColor={isCustomReport ? 'orange' : 'green'}
                                pillText={
                                    isCustomReport
                                        ? 'Custom Report'
                                        : 'Arbor Report'
                                }
                            />
                        );
                    })}
                </FlexGridContainer>
            ) : (
                <LoadingSpinner />
            )}
        </div>
    );
};
