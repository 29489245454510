import classNames from 'classnames';
import {useRef, useState} from 'react';

import {FocusTrappingModal} from 'Components/focusTrappingModal';
import {Icon} from 'Components/icon';

import {Filter} from '../types';

import {DahsboardFiltericon} from './DashboardFilterIcon';
import {DashboardFilterPanelOption} from './DashboardFilterPanelOption';

type DashboardFilterPanelProps = {
    filters: Filter[];
    updateFilters: (filters) => void;
};

export const DashboardFilterPanel = ({
    filters,
    updateFilters,
}: DashboardFilterPanelProps) => {
    const [editingFilters, setEditingFilters] = useState(false);
    const [activeFilters, setActiveFilters] = useState(filters);
    const updatedFilters = useRef(filters);

    const refetchData = (newFilters) => {
        if (!newFilters.length) {
            updateFilters(null);
            return;
        }

        // this needs mapped properly when we have more than one filter
        const mappedFilters = [
            {
                member: newFilters[0].dimension,
                operator: newFilters[0].operator,
                values: newFilters[0].currentValues,
            },
        ];

        updateFilters(mappedFilters);
    };

    const selectSingleFilter = (selectedFilter, filterValue) => {
        const activeFilter = structuredClone(filters).find(
            (filter) => filter.dimension === selectedFilter,
        );

        if (!activeFilter?.currentValues) {
            throw new Error(
                'Filter not found, could not update list of current filters',
            );
        }

        activeFilter.currentValues[0] = filterValue;

        const newFilters = structuredClone(filters);
        newFilters.map((filter) => {
            if (filter.dimension === activeFilter.dimension) {
                filter.currentValues = activeFilter.currentValues;
            }
        });

        updatedFilters.current = newFilters;
    };

    return (
        <div
            className={classNames('dashboard-filter-panel', {
                'dashboard-filter-panel__inactive': !activeFilters.length,
            })}
        >
            <div className="dashboard-filter-panel__content">
                <div
                    className={classNames(
                        'dashboard-filter-panel-filters-icon',
                        {
                            'dashboard-filter-panel-filters-icon__active':
                                !!activeFilters.length,
                        },
                    )}
                >
                    <DahsboardFiltericon />
                    {activeFilters.length}
                </div>
                {!activeFilters.length && (
                    <>
                        <div className="dashboard-filter-panel-status__inactive">
                            No Filters Applied.
                        </div>
                    </>
                )}
                {activeFilters.length > 0 && (
                    <>
                        <div className="dashboard-filter-panel-status__active">
                            Filters Applied:
                        </div>
                        {activeFilters.map((filter) => {
                            return (
                                <div className="dashboard-filter-panel__filter-option">
                                    <div className="dashboard-filter-panel__filter-option__active">
                                        {filter.title + ': '}
                                    </div>
                                    {filter.currentValues &&
                                        filter.currentValues.map(
                                            (currentValue, index) => {
                                                return index > 0
                                                    ? ', ' + currentValue
                                                    : ' ' + currentValue;
                                            },
                                        )}
                                </div>
                            );
                        })}
                    </>
                )}
                <div
                    className="dashboard-filter-panel-edit-button"
                    onClick={() => setEditingFilters(true)}
                >
                    <Icon iconName="pencil" />
                    <div className="dashboard-filter-panel-edit-button-text">
                        Edit
                    </div>
                </div>
            </div>
            {editingFilters && (
                <FocusTrappingModal
                    onClose={() => {
                        updatedFilters.current = filters;
                        setEditingFilters(false);
                    }}
                    title="Global Filters"
                    hideFullScreenButton
                >
                    <div className="dashboard-filter-panel-popup-filter-options">
                        {activeFilters.map((filter) => {
                            return (
                                <div className="dashboard-filter-panel-popup-filter-option">
                                    <div className="dashboard-filter-panel-popup-filter-title">
                                        {filter.title}
                                    </div>
                                    {!filter.multiselect ? (
                                        <select
                                            onChange={(e) =>
                                                selectSingleFilter(
                                                    filter.dimension,
                                                    e.target.value,
                                                )
                                            }
                                            className="dashboard-filter-panel-filter-option-dropdown"
                                        >
                                            {filter.values.map((value) => {
                                                // non multiselect so safe to assume first value is only current value
                                                const selected =
                                                    (filter
                                                        .currentValues?.[0] ??
                                                        '') === value;
                                                return (
                                                    <option
                                                        value={value}
                                                        selected={selected}
                                                    >
                                                        {value}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    ) : (
                                        <div className="dashboard-filter-panel-popup-filter-option-values">
                                            {filter.values.map((value) => {
                                                const isActive = (
                                                    filter.currentValues ?? []
                                                ).includes(value);
                                                return (
                                                    <DashboardFilterPanelOption
                                                        value={value}
                                                        isActive={isActive}
                                                        setActiveFilters={
                                                            setActiveFilters
                                                        }
                                                    />
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                    <div className="dashboard-filter-panel-popup-button-container">
                        <button
                            className={classNames(
                                'dashboard-filter-panel-popup-button',
                                'dashboard-filter-panel-popup-button__cancel',
                            )}
                            onClick={() => {
                                updatedFilters.current = filters;
                                setEditingFilters(false);
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            className={classNames(
                                'dashboard-filter-panel-popup-button',
                                'dashboard-filter-panel-popup-button__clear-all',
                            )}
                            onClick={() => {
                                refetchData([]);
                                setActiveFilters(filters);
                                setEditingFilters(false);
                            }}
                        >
                            Clear All
                        </button>
                        <button
                            className={classNames(
                                'dashboard-filter-panel-popup-button',
                                'dashboard-filter-panel-popup-button__save-changes',
                            )}
                            onClick={() => {
                                refetchData(updatedFilters.current);
                                setActiveFilters(updatedFilters.current);
                                setEditingFilters(false);
                            }}
                        >
                            Save Changes
                        </button>
                    </div>
                </FocusTrappingModal>
            )}
        </div>
    );
};
