import {useQuery} from '@tanstack/react-query';
import React from 'react';

import {fetchDashboardItemData} from './fetchDashboardItemData';

export const DashboardItemWithDataFetching = ({
    dataQuery,
    chartQueryKey,
    children,
}) => {
    const {data, isError, isLoading} = useQuery({
        queryKey: [chartQueryKey, dataQuery],
        queryFn: () => fetchDashboardItemData([chartQueryKey, dataQuery]),
    });

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Error fetching data</div>;
    }

    return React.cloneElement(children, {data});
};
