import classNames from 'classnames';

import {Button} from 'Components/button/base';
import {SearchField} from 'Components/searchField';

type ReportingDashboardHeaderProps = {
    isLoading: boolean;
    categories: string[];
    selectedCategory: string;
    setSelectedCategory: (selectedCategory: string) => void;
    showArborReports: boolean;
    showCustomReports: boolean;
    showOnlyMyCustomReports: boolean;
    setShowArborReports: (newVal: boolean) => void;
    setShowCustomReports: (newVal: boolean) => void;
    setShowOnlyMyCustomReports: (newVal: boolean) => void;
    setSearchQuery: (query: string) => void;
    displayCount: number;
    searchQuery?: string;
    totalCount: number;
};

export const ReportingDashboardHeader = ({
    categories,
    setSearchQuery,
    selectedCategory,
    setSelectedCategory,
    displayCount,
    totalCount,
    showArborReports,
    showCustomReports,
    showOnlyMyCustomReports,
    setShowArborReports,
    setShowCustomReports,
    setShowOnlyMyCustomReports,
    searchQuery,
}: ReportingDashboardHeaderProps) => {
    return (
        <div className="reporting-dashboard__header">
            <span className="reporting-dashboard__header__report-origin-container">
                <label>
                    <input
                        checked={showArborReports}
                        type="checkbox"
                        onChange={() => {
                            setShowArborReports(!showArborReports);
                        }}
                    />
                    Arbor Reports
                </label>
                <div className="reporting-dashboard__header__custom-reports">
                    <label>
                        <input
                            checked={showCustomReports}
                            type="checkbox"
                            onChange={() => {
                                if (!showCustomReports) {
                                    setShowCustomReports(true);
                                } else {
                                    setShowCustomReports(false);
                                    setShowOnlyMyCustomReports(false);
                                }
                            }}
                        />
                        Custom Reports
                    </label>
                    <label>
                        <input
                            checked={showOnlyMyCustomReports}
                            type="checkbox"
                            disabled={!showCustomReports}
                            onChange={() => {
                                setShowOnlyMyCustomReports(
                                    !showOnlyMyCustomReports,
                                );
                            }}
                        />
                        Only show my custom reports
                    </label>
                </div>
            </span>
            <div className="reporting-dashboard__header__lower">
                <div className="reporting-dashboard__header__categories">
                    {categories.map((category) => {
                        const isSelected = selectedCategory === category;
                        return (
                            <Button
                                key={category}
                                text={category}
                                onClick={(event) => {
                                    event.preventDefault();
                                    if (isSelected) {
                                        setSelectedCategory('All');
                                    } else {
                                        setSelectedCategory(category);
                                    }
                                }}
                                className={classNames(
                                    'search-filters__button',
                                    {
                                        'search-filters__button--active':
                                            isSelected,
                                    },
                                )}
                                fontSize="14px"
                            />
                        );
                    })}
                </div>
                <div className="reporting-dashboard__header__right-align-container">
                    <SearchField
                        placeholder="Search Reports"
                        onChange={(e) => {
                            setSearchQuery(e.target.value);
                        }}
                        value={searchQuery}
                    />
                    <p
                        data-testid="ReportingDashboard-reportCount"
                        className="reporting-dashboard__counter"
                    >
                        Showing{' '}
                        <b>
                            {displayCount}/{totalCount}
                        </b>{' '}
                        reports
                    </p>
                </div>
            </div>
        </div>
    );
};
