// These are the names of all the modules in SIS that can be
// specified in SIS application config
export const sisModuleNames = [
    'finance',
    'guardians',
    'students',
    'group',
    'LA',
    'UkDfe',
    'UkJcq',
    'UkIlr',
    'hn',
    'group-hn',
    'SaCoe',
    'SharedTeaching',
    'DeNi',
];
